<template>
  <b-row style="position: relative;">
    <b-col md="12">
      <div class="clearfix">
        <div class="pull-left mb-2">
          <b-button variant="success" @click="save()">저장</b-button>
        </div>
      </div>
      <b-card>
        <div slot="header">
          <strong>기본정보</strong>
        </div>
        <b-form>
          <b-form-group label="카테고리" :label-cols="1">
            <b-form-select :plain="true" :options="[
                              {value: '010', text: '남성'},
                              {value: '009', text: '여성'},
                           ]" v-model="config.category">
            </b-form-select>
          </b-form-group>
          <b-form-group label="상태" :label-cols="1">
            <b-form-select :plain="true" :options="[{value: 'auto', text: '자동'},{value: 'on', text: '강제노출'},]" value="" v-model="config.status">
            </b-form-select>
          </b-form-group>
          <b-form-group label="시작" :label-cols="1">
            <b-form inline>
              <masked-input
                type="text"
                name="date"
                class="form-control w-date"
                v-model="config.s_date"
                :mask="[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
                :guide="true"
                placeholderChar="_"
                :showMask="true"
                :keepCharPositions="true"
                :pipe="autoCorrectedDatePipe()">
              </masked-input>
              <label class="mx-sm-2"></label>
              <b-form-select :plain="true" v-model="config.s_hour" value="0" :options="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]"></b-form-select>
              <label class="mx-sm-2">시</label>
              <b-form-select :plain="true" v-model="config.s_minute" :options="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]"></b-form-select>
              <label class="mx-sm-2">분</label>
            </b-form>
          </b-form-group>
          <b-form-group label="종료" :label-cols="1">
            <b-form-select :plain="true" v-model="config.end_type" value="0"
                           :options="[
                           {value: 'days', text: '시작일로부터 N일'},
                           {value: 'date', text: '직접입력'},
                           ]"></b-form-select>
            <b-form inline v-if="config.end_type === 'days'" class="mt-3">
              <b-form-select :plain="true" v-model="config.e_days" value="0" :options="[1, 2, 3, 4, 5, 6, 7]"></b-form-select>
              <label class="mx-sm-2">일</label>
            </b-form>
            <b-form inline v-if="config.end_type === 'date'" class="mt-3">
              <masked-input
                type="text"
                name="date"
                class="form-control w-date"
                v-model="config.e_date"
                :mask="[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
                :guide="true"
                placeholderChar="_"
                :showMask="true"
                :keepCharPositions="true"
                :pipe="autoCorrectedDatePipe()">
              </masked-input>
              <label class="mx-sm-2"></label>
              <b-form-select :plain="true" v-model="config.e_hour" value="0" :options="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]"></b-form-select>
              <label class="mx-sm-2">시</label>
              <b-form-select :plain="true" v-model="config.e_minute" :options="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]"></b-form-select>
              <label class="mx-sm-2">분</label>
            </b-form>
          </b-form-group>
        </b-form>
      </b-card>

      <b-card>
        <div slot="header">
          <strong class="mr-2">상품</strong> <small>10개의 상품이 노출됩니다. 10개 이상 추가할 경우, 솔드아웃 시 순서대로 대체상품으로 노출됩니다.</small>
        </div>
        <div class="clearfix mb-3">
          <div class="pull-left">
            <b-button @click="resetRows()">초기화</b-button>
            <b-button variant="primary ml-3" @click="createRow()">추가</b-button>
          </div>
        </div>
        <b-card no-body class="mb-1" bg-variant="primary">
          <b-card-body class="py-3">
            <b-row class="text-center">
              <b-col cols="1">Goodsno*</b-col>
              <b-col cols="1">이미지</b-col>
              <b-col cols="2">상품정보</b-col>
              <b-col cols="3">대체 이미지 URL / 상품명</b-col>
              <b-col cols="2">가격</b-col>
              <b-col cols="1">재고</b-col>
              <b-col cols="2">관리</b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <draggable>
          <div v-for="(e, i) in goods">
            <b-card no-body class="mb-1 text-center">
              <b-row class="p-3">
                <b-col cols="1">
                  <b-form-input type="text" v-model="e.goodsno" @keyup="getGoods(e)" placeholder="Goodsno"></b-form-input>
                </b-col>
                <b-col cols="1">
                  <img v-if="e.img_url" :src="e.img_url" style="height:60px;"/>
                  <span v-else>-</span>
                </b-col>
                <b-col cols="2" class="text-left" v-if="e.goodsnm">
                  <b-badge variant="success" class="mr-1">{{e.shop_id}}{{(e.shop) ? '.'+e.shop : ''}}</b-badge>
                  <b-badge variant="warning" class="mr-1">{{e.brand}}</b-badge>
                  <b-badge variant="light">{{e.category}}</b-badge>
                  <div>{{e.goodsnm}}</div>
                  <div v-if="e.options.length">사이즈
                    <span v-for="option in e.options">
                      <b-badge variant="light">{{option.Size}}</b-badge>
                      <b-badge variant="success">{{option.stock}}</b-badge>
                    </span>
                  </div>
                </b-col>
                <b-col cols="2" v-else>
                  -
                </b-col>
                <b-col cols="3">
                  <b-form-input type="text" placeholder="이미지 URL을 입력해주세요" v-model="e.replace_img_url"></b-form-input>
                  <b-form-input class="mt-2" type="text" placeholder="상품명을 입력해주세요" v-model="e.replace_goodsnm"></b-form-input>
                </b-col>
                <b-col v-if="e.sale_price">
                  <div style="text-decoration: line-through" v-if="e.consumer_price != e.sale_price">{{e.consumer_price}}</div>
                  <div>{{e.sale_price}}</div>
                  <div v-if="(e.consumer_price != e.sale_price) && e.per">{{e.per + '%'}}</div>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
                <b-col cols="1" v-if="e.tot_stock">{{e.tot_stock}}</b-col>
                <b-col cols="1" v-else>-</b-col>
                <b-col cols="2"><b-button variant="danger" @click="removeRow(i)">삭제</b-button></b-col>
              </b-row>
            </b-card>
          </div>
        </draggable>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import cTable from '@/views/TableBase.vue';
import draggable from 'vuedraggable'
import {getMeta, getJson, postJson} from '@/shared/api';
import * as utils from '@/shared/utils'
import * as moment from 'moment-timezone';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import MaskedInput from 'vue-text-mask'

import * as axios from 'axios';
import vSelect from "vue-select";
import {DatePicker} from "v-calendar";

export default {
  name: "MainSet",
  components: {cTable, draggable, MaskedInput},
  data() {
    return {
      params: this.$route.query,
      id: (this.$route.query.mode === 'modify') ? this.$route.query.id : null,
      gender_code: null,
      key_event_delay: null,
      categoryMap: {},
      progress_events: [{text: '성별을 선택해주세요', value: null}],
      config: {
        category: '010',
        status: 'auto',
        startdt: '',
        enddt: '',
        s_date: '',
        s_hour: '',
        s_minute: '',
        end_type: '',
        e_days: '',
        e_date: '',
        e_hour: '',
        e_minute: '',
      },
      goods_preset: {
        goodsno: '',
        img_url: '',
        replace_goodsnm: '',
        replace_img_url: '',
        shop: '',
        brand: '',
        category: '',
        goodsnm: '',
        options: [],
        sale_price: '',
        consumer_price: '',
        per: '',
        tot_stock: '',
      },
      goods: [],
      init_goods: [],
    }
  },
  async created() {
    // 카테고리 매핑
    let meta = await getMeta('category');
    meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value:e.category, label:`${e.category} (${e.category_nm})`};
    }).sort((a,b)=>(a.value.length - b.value.length)*10 + a.value.localeCompare(b.value));

    let data = await this.getData();

    if (data.goods && data.config) {
      this.goods = data.goods.map(e => {
        return {
          ...utils.clone(this.goods_preset),
          goodsno: e.goodsno,
          replace_goodsnm: e.replace_goodsnm,
          replace_img_url: e.replace_img_url,
        };
      });
      this.goods.forEach(e => this.getGoods(e, true));
      let s = moment(utils.clone(data.config.startdt));
      let e = moment(utils.clone(data.config.enddt));
      this.config = {
        ...data.config,
        s_date: s.format('YYYY-MM-DD'),
        s_hour: s.format('H'),
        s_minute: s.format('m'),
        end_type: 'date',
        e_date: e.format('YYYY-MM-DD'),
        e_hour: e.format('H'),
        e_minute: e.format('m'),
      };
    } else {
      this.goods = Array(5).fill(0).map(e => utils.clone(this.goods_preset));
    }
    this.init_goods = this.goods;
  },
  methods: {
    async getData() {
      return await getJson(`/godo/getTimesaleSetDetail?id=${this.id}`);
    },
    async removeRow(idx, section) {
      this.goods.splice(idx, 1);
    },
    async createRow() {
      this.goods.push(utils.clone(this.goods_preset));
    },
    async resetRows() {
      this.goods = utils.clone(this.init_goods);
    },
    async getGoods(row, force=false) {
      let goodsno_list = [];
      let time = 0;
      if (!force) {
        clearTimeout(this.key_event_delay);
        time = 500;
      }
      this.key_event_delay = setTimeout(async () => {
        goodsno_list.push(+row.goodsno);
        let data = await postJson('/goods/confirmed', {search:'', goods_no_include: goodsno_list});
        data = (data.list.length) ? data.list[0] : {
          img_url: '',
          shop: '',
          brand: '',
          category: '',
          goodsnm: '',
          options: '',
          sale_price: '',
          consumer_price: '',
          per: '',
          tot_stock: '',
        };
        row.img_url = (data.img_urls) ? data.img_urls[0] : '';
        row.shop = data.shop;
        row.brand = data.brand_nm;
        if (data.category) {
          let cates = [];
          for (let i = 3; i <= data.category.length; i += 3) {
            if (data.category.substring(i - 3, i) === '001') cates.push('전체');
            else cates.push(this.categoryMap[data.category.substring(0, i)] && this.categoryMap[data.category.substring(0, i)].category_nm || '?');
          }
          row.category = cates.join(' > ');
        } else {
          row.category = data.category;
        }
        row.goodsnm = data.goods_nm;
        row.options = data.options;
        row.sale_price = utils.comma(data.price);
        row.consumer_price = utils.comma(data.consumer);
        row.per = Math.ceil((data.consumer - data.price) / data.consumer * 100);
        row.tot_stock = (data.tot_stock === 0) ? 'SOLD OUT' : data.tot_stock;
      }, time);
    },
    async cancel() {
      if (confirm('저장하지 않은 데이터는 사라집니다. 취소하시겠습니까?')) {
        location.href = '/#/main_home';
      }
    },
    async save() {
      let id = this.id;
      let data = {config: this.config, goods: this.goods};
      let result = await postJson('/godo/saveTimeSaleSet', {id, data});
      if (result) {
        alert("저장되었습니다");
        if (this.params.mode === 'add') this.id = result.id;
        location.href = '/#/timesale';
      }
    },
    autoCorrectedDatePipe() { return createAutoCorrectedDatePipe('yyyy-mm-dd') },
  }
}
</script>

<style scoped>

</style>
